import React from 'react';
import { VIEWER_HOOKS } from '@wix/pricing-plans-common/hooks';
import { useStyles } from '@wix/tpa-settings/react';
import { useEnvironment, useTranslation, useFedopsLogger } from '@wix/yoshi-flow-editor';
import { TabState } from '../../../../constants/settings-events';
import { useAdaptedSettings, useStylesParams } from '../../../../hooks';
import { MessageCode, ListProps } from '../../../../types/common';
import { alignmentName } from '../../../../utils/alignment';
import { messageToText, isMessageInformational } from '../../../../utils/errors';
import { Toast } from '../Toast';
import { EmptyState, EmptyStateProps } from './EmptyState';
import { st, classes } from './List.st.css';
import { PlanList, MAX_ITEM_WIDTH } from './PlanList';
import Popup from './Popup';

export interface ListWidgetProps extends ListProps {
  tabState: TabState;
  areMobileSettingsOpened: boolean;
  interactionEnd: string;
  message?: MessageCode;
  dataHook?: string;
  hideToast(): void;
  closeUpgradeModal(): void;
  emptyState: EmptyStateProps;
  locale?: string;
}

const List: React.FC<ListWidgetProps> = ({
  plans,
  tabState,
  areMobileSettingsOpened,
  selectPlan,
  popup,
  hidePopup,
  message,
  dataHook,
  hideToast,
  interactionEnd,
  emptyState,
  selectedPlanId,
  overrideAppTitle,
  overrideAppSubtitle,
}) => {
  const { t } = useTranslation();
  const { isMobile, isEditorX } = useEnvironment();
  const fedops = useFedopsLogger();
  const stylesParams = useStylesParams();
  const settings = useAdaptedSettings();
  const styles = useStyles();

  const showPageTitle = styles.get(stylesParams.showPageTitle) || Boolean(overrideAppTitle);
  const showPageSubtitle = settings.get('showPageSubtitle') || Boolean(overrideAppSubtitle);
  const showHeader = showPageTitle || showPageSubtitle;
  const showDisclaimer = styles.get(stylesParams.showDisclaimer);

  const titleAlignment = alignmentName(styles.get(stylesParams.titleAlignment));
  const subtitleAlignment = alignmentName(styles.get(stylesParams.subtitleAlignment));
  const disclaimerAlignment = alignmentName(styles.get(stylesParams.disclaimerAlignment));

  const TitleTag = settings.get('titleHtmlTag') as keyof JSX.IntrinsicElements;

  React.useEffect(() => {
    fedops.interactionEnded(interactionEnd);
  }, []);

  const visiblePlanCount = plans.length;
  const extraPlanListPadding = 30;
  const maxWidthStyle =
    !isEditorX && visiblePlanCount ? { maxWidth: MAX_ITEM_WIDTH * visiblePlanCount - extraPlanListPadding } : {};

  if (plans.length === 0) {
    return <EmptyState {...emptyState} />;
  }

  return (
    <div className={st(classes.root, { mobile: !!isMobile })} data-hook={dataHook ?? 'PackagePicker-wrapper'}>
      {showHeader && (
        <div className={classes.header} style={maxWidthStyle}>
          {showPageTitle && (
            <TitleTag className={st(classes.title, { textAlignment: titleAlignment })} data-hook="app-title">
              {overrideAppTitle || settings.get('pageHeaderText') || t('pp.defaults.pageTitle')}
            </TitleTag>
          )}
          {showPageSubtitle && (
            <p
              className={st(classes.subtitle, { textAlignment: subtitleAlignment })}
              data-hook={VIEWER_HOOKS.PP_WIDGET_SUBTITLE}
            >
              {overrideAppSubtitle || settings.get('pageSubtitleText') || t('pp.defaults.pageSubtitle')}
            </p>
          )}
        </div>
      )}
      {message ? (
        <Toast onClose={hideToast} informational={isMessageInformational(message)}>
          {t(messageToText(message))}
        </Toast>
      ) : null}
      <PlanList
        selectPlan={selectPlan}
        plans={plans}
        showDemoHighlight={tabState === TabState.HIGHLIGHTED}
        expandMobileBenefits={areMobileSettingsOpened}
        selectedPlanId={selectedPlanId}
      />
      {showDisclaimer && (
        <div
          className={st(classes.disclaimer, {
            textAlignment: disclaimerAlignment,
          })}
          style={maxWidthStyle}
        >
          <p>{settings.get('disclaimerText')}</p>
        </div>
      )}
      {popup ? <Popup popup={popup} hidePopup={hidePopup} /> : null}
    </div>
  );
};

export default List;
